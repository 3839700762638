import React from 'react'

import { OrderPage } from '../components'

class Orders extends React.Component {
  render() {
    return <OrderPage />
  }
}

export default Orders
